import Storage from "./storage";

const CredentialsHelper = {
    update: function (accessToken) {
        Storage.setItem(ACCESS_TOKEN_ID, accessToken);
    },
    clear: function () {
        Storage.removeItem(ACCESS_TOKEN_ID);
    },
    accessToken: () => Storage.getItem(ACCESS_TOKEN_ID),
    isLoggedIn: () => (CredentialsHelper.accessToken())
};

export default CredentialsHelper;

const ACCESS_TOKEN_ID = 'access_token';

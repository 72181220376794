import { all, takeEvery, put, call } from 'redux-saga/effects';
import { ActionTypesSideMenu, controlIsLoading, controlNavItens, controlSwaggerUI } from '../reducers/side-menu.reducer';
import { getNavItens, getLastCommit, getSwaggerUI } from '../utils/webApi';
import handleError from '../utils/handleError';


function* asyncGetNavItens () {
    try {

        yield put(controlIsLoading(true));

        const response = yield call(getNavItens);

        yield put(controlNavItens(response.apis));
        yield asyncGetSwagger({ payload: response.apis[0]});
    } catch(err) {
        handleError(err);
    } finally {
        yield put(controlIsLoading(false));
    }
};

function* asyncGetSwagger({ payload }) {
    try {
        yield put(controlIsLoading(true));

        const { repository, path } = payload;
        const responseLastCommit = yield call(getLastCommit, repository);
        const lastCommit = responseLastCommit.values[0].hash
        const response = yield call (getSwaggerUI, repository, lastCommit, path);
        
        yield put(controlSwaggerUI(response));

    } catch(err) {
        yield put(controlSwaggerUI(undefined));
        handleError(err);
    } finally {
        yield put(controlIsLoading(false));
    }
}


export default function* mySaga() {
    yield all([
        takeEvery(ActionTypesSideMenu.ASYNC_GET_SIDE_MENU_ITENS, asyncGetNavItens),
        takeEvery(ActionTypesSideMenu.ASYNC_GET_SWAGGER_UI, asyncGetSwagger)
    ]);
}
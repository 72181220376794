import { all } from 'redux-saga/effects';

import Auth from './auth.saga';
import SideMenu from './side-menu.saga';


export default function* RootSaga() {
    yield all([
        Auth(),
        SideMenu()
    ]);
};
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RedocStandalone } from 'redoc';
import SwaggerUI from "swagger-ui-react"
import YAML from 'yamljs';
import "swagger-ui-react/swagger-ui.css"
import Loading from '../../components/loading/Loading';

const RenderApiUI = () => {

    const { swaggerUI, isSwagger, isLoading } = useSelector(({ SideMenu }) => ({ 
        swaggerUI: SideMenu.swaggerUI, 
        isSwagger: SideMenu.isSwagger, 
        isLoading: SideMenu.isLoading 
        }));

    const currentSwagger = useMemo(() => {
        if (swaggerUI) return YAML.parse(swaggerUI);
        return undefined;
    }, [swaggerUI]);

    return (
        <>
            {isLoading && <Loading />}
            {!swaggerUI
                && <p>
                    Selecione um Repositorio válido, e ou que você tenha acesso.
                </p>}
            {swaggerUI && (isSwagger ? <SwaggerUI spec={currentSwagger} /> : <RedocStandalone spec={currentSwagger} />)}
        </>
    )
};

export default RenderApiUI;
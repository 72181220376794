import React from 'react';
import { Route, Redirect, Switch } from "react-router-dom";
import ApiList from './containers/api-list/ApiList';
import { routes } from './utils/routers.constants';
import Login from './containers/login/Login';
import CredentialsHelper from './utils/credentials-helper';

import './App.scss';

const App = () => {

  const isLogged = CredentialsHelper.isLoggedIn();

  return (
    <Switch>
      { isLogged && <Route path={routes.CONTAINER} component={ApiList} />}
      <Route path={routes.LOGIN} component={Login} />
      <Redirect from={routes.ROOT} to={isLogged ? routes.CONTAINER : routes.LOGIN} />
    </Switch>
  );
}

export default App;
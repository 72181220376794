import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import SideMenu from '../reducers/side-menu.reducer';


const RootReducer = (history) => combineReducers({
    router: connectRouter(history),
    SideMenu
});

export default RootReducer;
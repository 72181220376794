import Store from "../config/Store.config";
import { expiredAccessToken } from "../reducers/auth.reducer";

function handleError(error) {
    const { status, data } = error.response || {};

    if (status === 401 &&  (typeof(data) === "object")){
        Store.dispatch(expiredAccessToken());
        return;
    };
};

export default handleError;
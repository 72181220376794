
export const AuthActionTypes = {
    ASYNC_DO_LOGIN : 'ASYNC_DO_LOGIN',
    ASYNC_DO_LOGOUT : 'ASYNC_DO_LOGOUT',
    EXPIRED_ACCESS_TOKEN: 'EXPIRED_ACCESS_TOKEN'
};


export const doLogin = (access_token) => ({
    type: AuthActionTypes.ASYNC_DO_LOGIN,
    payload: access_token
});

export const doLogout = () => ({
    type: AuthActionTypes.ASYNC_DO_LOGOUT
});

export const expiredAccessToken = () => ({
    type: AuthActionTypes.EXPIRED_ACCESS_TOKEN
});

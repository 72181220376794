
import Axios from 'axios';
import CredentialsHelper from './credentials-helper';

const baseHeaders = (othersHeaders = {}) => ({
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CredentialsHelper.accessToken()}`,
        ...othersHeaders
    }
});

export const Get = (url, headers) => {
    return Axios.get(url, baseHeaders(headers))
        .then(data => data.data)
        .catch(error => {
            throw error;
        });
};
const Storage = {
    setItem: function (key, value) {
        localStorage.setItem(key, value);
    },
    removeItem: function (key) {
        localStorage.removeItem(key);
    },

    getItem: (key) => localStorage.getItem(key),

    clear: function () {
        localStorage.clear();
    }
};

export default Storage;

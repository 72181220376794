import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { asyncGetNavItens, asyncGetSwaggerUI, controlIsSwagger } from '../../reducers/side-menu.reducer';
import { doLogout } from '../../reducers/auth.reducer';
import Loading from '../loading/Loading';

import './SideMenu.scss';
 
const SideMenu = () =>{

    const refSideBar = useRef(null);
    const dispatch = useDispatch();

    const { navItens, isSwagger } = useSelector(({ SideMenu }) => ({ 
        navItens: SideMenu.navItens, 
        isSwagger: SideMenu.isSwagger
    }));

    useEffect(()=>{
        dispatch(asyncGetNavItens());
    // eslint-disable-next-line
    },[]);

    return (
        
        <div className="side-bar" ref={refSideBar} >

            {(!navItens[0]) ? <Loading/> : 
            <>  
                <div className="menu-sidebar" > {
                    <div class="div-select">
                        <select onChange={({ target })=> dispatch(asyncGetSwaggerUI(navItens[target.value]))} >
                            { navItens.map((element, index)=> (
                            <option 
                                className="sidebar-itens"
                                value={index}>
                                {element.name}</option>
                            ))}
                        </select>  
                    </div>
                }
                </div>

                <div className="container-toggle-logout" >
                    <span className="swagger-title-toggle" >swagger</span>
                    <div>
                        <label class="switch">
                            <input type="checkbox" checked={isSwagger} onChange={()=>dispatch(controlIsSwagger(!isSwagger))} />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>

                <button className="button-logout" onClick={()=> dispatch(doLogout())}>logout</button>
            </>}

        </div>
    );
}

export default SideMenu;
import React, { useEffect } from 'react';
import { externalUrls } from '../../utils/urls.constants';
import { useDispatch } from 'react-redux';
import { doLogin } from '../../reducers/auth.reducer';
import { getAccessToken } from '../../utils/functions';

const Login = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const access_token = getAccessToken(window.location.href)
        if(!access_token) {
            window.location.href = externalUrls.LOGIN;
        } else {
            dispatch(doLogin(access_token));
        }
    // eslint-disable-next-line
    }, []);

    return <p>Redirecionando...</p>;
};

export default Login;
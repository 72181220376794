export const ActionTypesSideMenu = {
    ASYNC_GET_SIDE_MENU_ITENS: 'ASYNC_GET_SIDE_MENU_ITENS',
    SIDE_MENU_ISLOADING: 'SIDE_MENU_ISLOADING',
    SIDE_MENU_NAV_ITENS: 'SIDE_MENU_NAV_ITENS',
    SIDE_MENU_SWAGGER_UI: 'SIDE_MENU_SWAGGER_UI',
    ASYNC_GET_SWAGGER_UI: 'ASYNC_GET_SWAGGER_UI',
    SIDE_MENU_IS_SWAGGER: 'SIDE_MENU_IS_SWAGGER'
};


const INITIAL_STATE = {
    navItens : [],
    isLoading: false,
    swaggerUI: undefined,
    isSwagger: false
};

const reducer = (state = INITIAL_STATE, action) => {
    const { type } = action
    switch(type) {
        case ActionTypesSideMenu.SIDE_MENU_NAV_ITENS:
            return { ...state, navItens: action.payload }
        case ActionTypesSideMenu.SIDE_MENU_ISLOADING:
            return { ...state, isLoading: action.payload }
        case ActionTypesSideMenu.SIDE_MENU_SWAGGER_UI:
            return { ...state, swaggerUI: action.payload }
        case ActionTypesSideMenu.SIDE_MENU_IS_SWAGGER:
            return { ...state, isSwagger: action.payload }
        default:
            return state;
    }
};

export default reducer;


export const asyncGetNavItens = () => ({
    type: ActionTypesSideMenu.ASYNC_GET_SIDE_MENU_ITENS
});

export const asyncGetSwaggerUI = (value) => ({
    type: ActionTypesSideMenu.ASYNC_GET_SWAGGER_UI,
    payload: value
});


export const controlIsLoading = (value) => ({
    type: ActionTypesSideMenu.SIDE_MENU_ISLOADING,
    payload: value
});

export const controlIsSwagger = (value) => ({
    type: ActionTypesSideMenu.SIDE_MENU_IS_SWAGGER,
    payload: value
});

export const controlNavItens = (value) => ({
    type: ActionTypesSideMenu.SIDE_MENU_NAV_ITENS,
    payload: value
});

export const controlSwaggerUI = (value) => ({
    type: ActionTypesSideMenu.SIDE_MENU_SWAGGER_UI,
    payload: value
});
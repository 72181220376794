export function getUrlQuery(){
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++){
       hash = hashes[i].split('=');
       vars.push(hash[0]);
       vars[hash[0]] = hash[1];
    }
    return vars;
}

export function getAccessToken(url){
    return url.includes('#') ? url.split('#')[1].split('&')[0].split('=')[1] : undefined
}

// https://docs.api.scisapp.com/oauth2#access_token=3E3xPqmq2iai3mLwCJc0LaHuRRPL4MlWUcEoqNORJxbOaB3bccfOwBGaa-GxC7vZ_LKtAxNXAMs-nr52bsh2xVhh3Up_uTpxigyioQK6rHWvZiMyPTsqusqP&scopes=account+project+pullrequest&expires_in=7200&token_type=bearer
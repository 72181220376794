
const authUrls = {
    dev: ''
};
const clientIdStages = {
    dev: 'R6tBQHL8CuyrsBBwwX'
};
export const urlAuth = authUrls[process.env.REACT_APP_STAGE || 'dev'];
export const client_id = clientIdStages[process.env.REACT_APP_STAGE || 'dev']
export const settingsAuth = {
    client_id,
    redirect_uri_login: `${window.location.origin}/login`,
    redirect_uri_logout: `${window.location.origin}/logout`
};
export const externalUrls = {
    LOGIN: `https://bitbucket.org/site/oauth2/authorize?client_id=${settingsAuth.client_id}&response_type=token`,
    LOGOUT: `https://id.atlassian.com/logout?continue=https%3A%2F%2Fbitbucket.org%2Faccount%2Fsignout%2F`
};

export const url = {
    docs: 'https://docs.api.scisapp.com/apis.json',
    page: 'https://docs.api.scisapp.com'
}
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from '../../utils/routers.constants';
import SideMenu from '../../components/side-menu/SideMenu';
import RenderApiUI from '../render-api-UI/RenderApiUI';

import './ApiList.scss';

const ApiList = () => {

    return (
        <>
            <main className="api-list-container">
                <SideMenu/>

                <section className={`api-list-content  ${process.env.REACT_APP_STAGE || 'dev'}`}>
                    <Switch>
                        <Route exact path={routes.API_LIST} component={RenderApiUI} />
                        <Redirect from={routes.CONTAINER} to={routes.API_LIST} />
                    </Switch>
                </section>

            </main>
        </>
    );
}

export default ApiList;
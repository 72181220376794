import { all, takeEvery } from 'redux-saga/effects';
import { history } from '../config/Store.config';
import { AuthActionTypes } from '../reducers/auth.reducer';
import CredentialsHelper from '../utils/credentials-helper';
import { routes } from '../utils/routers.constants';
import handleError from '../utils/handleError';
import { externalUrls } from '../utils/urls.constants';

// eslint-disable-next-line
function* doLogin({ payload }) {
    try {
        CredentialsHelper.update(payload);
        history.push(routes.API_LIST);
    } catch (error) {
        handleError(error);
    }
};
// eslint-disable-next-line
function* doLogout() {
    try {
        window.location.href = externalUrls.LOGOUT;
        CredentialsHelper.clear();
    } catch (error) {
        handleError(error);
    }
};
// eslint-disable-next-line
function* doExpired() {
    try {
        CredentialsHelper.clear();
        history.push(routes.LOGIN);
    } catch (error) {
        handleError(error);
    }
};

export default function* MySaga() {
    yield all([
        yield takeEvery(AuthActionTypes.ASYNC_DO_LOGIN, doLogin),
        yield takeEvery(AuthActionTypes.ASYNC_DO_LOGOUT, doLogout),
        yield takeEvery(AuthActionTypes.EXPIRED_ACCESS_TOKEN, doExpired)
    ]);
}

import { Get} from "./apiBase"
import { url } from "./urls.constants"


/**
 * NavBar Api
 */

export const getNavItens = () => {
    return Get(url.docs)
};

export const getLastCommit = (repository) => {
    return Get(`https://api.bitbucket.org/2.0/repositories/fortalsistemas/${repository}/commits/develop?pagelen=1`)
};

export const getSwaggerUI = (repository, lastCommit, path) => {
    return Get(`https://api.bitbucket.org/2.0/repositories/fortalsistemas/${repository}/src/${lastCommit}/${path}`)
};

